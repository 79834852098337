const scrollMode = {
    init: () => {
        scrollMode.header = document.querySelector('header')
        scrollMode.lastScrollY = window.scrollY

        scrollMode.scheduledAnimationFrame = 0
        scrollMode.run()
    },

    run: () => {
        scrollMode.setScrollMode()
    },

    setScrollMode: () => {
        let scheduledAnimationFrame = false

        window.addEventListener('scroll', scrollMode.onScroll)
        scrollMode.onScroll()
    },

    readAndUpdatePage: () => {

        // Perform read and update operations here
        if (window.scrollY > scrollMode.lastScrollY) {
            document.body.classList.add('scroll-down')
            document.body.classList.remove('scroll-up')
        } else {
            document.body.classList.add('scroll-up')
            document.body.classList.remove('scroll-down')
        }

        // Store the scroll value for later
        scrollMode.lastScrollY = window.scrollY

        if (scrollMode.lastScrollY > scrollMode.header.offsetHeight) {
            document.body.classList.add('scroll')
        } else {
            document.body.classList.remove('scroll')
        }

        scrollMode.scheduledAnimationFrame = false
    },

    onScroll: () => {
        // Prevent multiple rAF callbacks
        if (scrollMode.scheduledAnimationFrame) {
            return
        }

        scrollMode.scheduledAnimationFrame = true
        requestAnimationFrame(scrollMode.readAndUpdatePage)
    },
}

document.addEventListener("DOMContentLoaded", function () {
    scrollMode.init();
});

