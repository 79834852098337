// Remove 'no-js' class indicating JavaScript availability.
$("html").removeClass("no-js");

// Add target="_blank" to PDF links.
$("a[href$='pdf']").attr("target", "_blank");

// Open External Links In New Window.
$('a').each(function () {
    if ($(this).attr("href") && ($(this).attr("href").indexOf("#") === 0 || $(this).attr("href").indexOf("javascript") === 0 || typeof this.href !== 'string')) {
        return;
    }

    var a = new RegExp('/' + window.location.host + '/');
    var b = new RegExp(/(mailto:.*)/g);
    var c = new RegExp(/(tel:.*)/g);

    if (!a.test(this.href) && !b.test(this.href) && !c.test(this.href)) {
        $(this).attr('rel', 'external');
        $(this).attr("target", "_blank");
    } else {
        $(this).attr('rel', 'internal');
    }
});

// Add 'hasImage' class to links that contain images.
$('a:has(img)').addClass('hasImage');

// Event listener for hash changes.
$(window).on('hashchange', function () {
    var hash = window.location.hash;
    $('html.mobile-nav-is-visible').removeClass('mobile-nav-is-visible');
    if (hash) {
        setTimeout(() => {
            scrollToElement(hash);
        }, 100);
    }
}).trigger('hashchange');

// Event listener for internal hash links.
$(document).on('click', 'header a[href^="#"], main a[href^="#"], footer a[href^="#"]', function (event) {
    var hash = $.attr(this, 'href');
    if (hash !== '#' && $(hash).length) {
        event.preventDefault();
        scrollToElement(hash);
        history.pushState(null, null, hash);
    }
});

document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll("a").forEach(function (link) {
        const href = link.getAttribute("href");

        // Skip links with no href, fragment links, or JavaScript pseudo-links
        if (!href || href.startsWith("#") || href.startsWith("javascript")) {
            return;
        }

        const currentHost = window.location.host;
        const isExternal = new RegExp(`^(https?:)?//(?!${currentHost.replace(/\./g, '\\.')})`, "i");

        if (isExternal.test(href)) {
            link.setAttribute("rel", "external");
            link.setAttribute("target", "_blank");
        } else {
            link.setAttribute("rel", "internal");
        }
    });
});
