const shopGateway = {
    init: function () {
        shopGateway.hasCookie = false;
        shopGateway.cookieName = 'owt_client';
        shopGateway.cookieDomain = 'officeworldtrade.ch';
        shopGateway.cookieValue = '';

        shopGateway.checkCookie();
        shopGateway.addListener();
    },

    addListener: function () {
        document.body.addEventListener('click', function (event) {
            const linkEl = event.target.closest('a[href*="100.officeworldtrade.ch"]');
            if (linkEl) {
                event.preventDefault();
                let url = linkEl.href;
                if (shopGateway.cookieValue) {
                    url = url.replace('100.', shopGateway.cookieValue + '.');
                }
                console.info('Redirecting to: ', url);
                window.open(url, '_blank');
            }
        });
    },

    checkCookie: function () {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].split('=');
            // console.log(cookie);
            if (cookie[0].trim() === shopGateway.cookieName) {
                shopGateway.hasCookie = true;
                shopGateway.cookieValue = cookie[1];
            }
        }
    }
}
// shop links to regenerate with api
window.addEventListener('DOMContentLoaded', (event) => {
    shopGateway.init();
});
