class Component extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
    }

    disconnectedCallback() {
    }
}

customElements.define('icon_box-component', Component);
