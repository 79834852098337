app.components.form = {
    onReady: function () {
        if ($('.wpforms-container').length) {
            return true;
        }
    },
    addListener: async function () {

        const container = $('.wpforms-container');

        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll(container);
        }).catch(error => {
            console.error('Error loading modules:', error);
        });

        $('input, textarea, select', container).on('input change', function () {
            var $field = $(this).closest('div.wpforms-field');
            if (this.value) {
                $field.addClass('field--not-empty');
            } else {
                $field.removeClass('field--not-empty');
            }
        }).trigger('input');

        $('input, textarea, select', container).on('focusin', function () {
            var $field = $(this).closest('div.wpforms-field');
            $field.addClass('field--not-empty');
        }).on('focusout', function () {
            $(this).trigger('input')
        });

        $('.wpforms-recaptcha-hidden').attr('area-hidden', 'true');

    }
};
