import Cookies from 'js-cookie';

app.components.cookie_policy = {
    onReady: function () {
        if ($('.cookie_policy').length) {
            return true;
        }
    },
    addListener: function () {
        $('.cookie_policy').on('click', function () {
            $(this).addClass('close');
        });

        $('.cookie_policy button.read').on('click', function () {
            Cookies.set('CookieConsent', 1, {expires: 365, path: '/'});
        });

        if (!Cookies.get('CookieConsent')) {
            $('.cookie_policy').removeClass('close');
        }

        // if (Cookies.get('CookieConsent')) {
        //     if (typeof window.Userback !== 'undefined') {
        //         (function (d) {
        //             var s = d.createElement('script');
        //             s.async = true;
        //             s.src = 'https://static.userback.io/widget/v1.js';
        //             (d.head || d.body).appendChild(s);
        //         })(document);
        //     }
        // }
    }
};
