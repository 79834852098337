app.components.teaser_slider = {
    onReady: function () {
        if ($('.teaser_slider').length) {
            return true;
        }
    },
    addListener: async function () {
        try {
            const [Swiper, swiperModules] = await Promise.all([
                import(/* webpackChunkName: "swiper" */ 'swiper'),
                import(/* webpackChunkName: "swiperPagination" */ 'swiper/modules'),
                import(/* webpackChunkName: "swiper" */ 'swiper/css')
            ]);
            const that = this;
            const SwiperLib = Swiper.default;
            $('.teaser_slider').each(function () {

                let swiper = {
                    container: $(".swiper-container", this)[0],
                    params: {
                        speed: 1000,
                        spaceBetween: 25,
                        loop: true,
                        preloadImages: true,
                        slidesPerView: 1.1,
                        watchOverflow: true,
                        autoHeight: false,
                        modules: [swiperModules.Navigation],
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        breakpoints: {
                            320: {
                                slidesPerView: 1.1,
                                spaceBetween: 25,
                                autoHeight: false,
                            },
                            769: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                                autoHeight: false,
                            },
                        },
                    }
                };
                if (typeof window.swiperwAccessibilityParameters !== 'undefined') {
                    swiper.params.a11y = window.swiperwAccessibilityParameters;
                }

                that.swiper = new SwiperLib($(".swiper-container", this)[0], swiper.params);

            });
        } catch (error) {
            console.error('Error loading modules:', error);
        }
    },
    unsetWrapperHeight: function () {
        const wrapper = document.querySelector('.swiper-wrapper');
        wrapper.style.height = '';
    },
    setWrapperHeight: function () {
        const wrapper = document.querySelector('.swiper-wrapper');
        wrapper.style.height = wrapper.offsetHeight + 'px';
    },
    resize: function () {
        const that = this;
        // Delay to wait for whatever rerendering...
        setTimeout(() => {
            if (!that.swiper) {
                return;
            }
            that.unsetWrapperHeight();
            that.swiper.update();
            that.setWrapperHeight();
        }, 1000);
    },
};
