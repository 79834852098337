class TeaserContactComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
    }

    disconnectedCallback() {
    }
}

customElements.define('teaser_contact-component', TeaserContactComponent);
