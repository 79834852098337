app.components.navigation = {
    breakpointLg: 1408,
    onReady: function () {
        if ($('.navigation__toggle').length) {
            return true;
        }
    },
    addListener: function () {
        const that = this;
        $('.navigation__toggle').on("click", function () {
            $('html').toggleClass('mobile-nav-is-visible');
            return false;
        });
        $('html').on("click", function (e) {
            if (window.innerWidth < that.breakpointLg) {
                if ($('html').hasClass('mobile-nav-is-visible')) {
                    const targetIsOutsideNavigation = e.target.closest('navigation-component');
                    if (!targetIsOutsideNavigation) {
                        $('.navigation__toggle').trigger("click");
                    }
                }
            }
        });
        this.enableBackButton();
        this.enableDrillDown();
        this.toggleSubMenus();
        //this.maintainLinkRels();
    },
    enableBackButton: function () {
        const backButton = document.querySelector(".back-button");
        backButton.addEventListener("click", function (event) {
            event.preventDefault();
            const subMenus = document.querySelectorAll(".sub-menu-wrapper.level_1");
            subMenus.forEach(subMenu => {
                subMenu.classList.remove("visible");
            });
            backButton.classList.remove("visible");
            const rootMenuItems = document.querySelectorAll("ul#menu-navigation > li > a");
            rootMenuItems.forEach(rootMenuItem => {
                rootMenuItem.classList.remove("hidden");
            });
            for (const submenu of event.target.closest("nav").querySelectorAll("li.is-open")) {
                submenu.classList.remove("is-open");
            }
        });
    },
    enableDrillDown: function () {
        const that = this;
        const backButton = document.querySelector(".back-button");
        const overviewMenuItem = document.querySelector("nav li.overview-menu-item");
        const rootMenuItems = document.querySelectorAll("ul.menu > li.menu_item_has_children > a");
        rootMenuItems.forEach(function (link) {
            link.addEventListener("click", function (event) {
                if (window.innerWidth > that.breakpointLg) {
                    return;
                }
                event.preventDefault();
                const subMenuWrapper = event.target.closest("li").querySelector(".sub-menu-wrapper.level_1");
                if (!subMenuWrapper) {
                    return;
                }
                const subMenu = subMenuWrapper.querySelector(".sub-menu.level_1");
                if (!subMenu) {
                    return;
                }
                const link = event.target.closest("a");
                const linkSource = link?.href || window.location.href;
                overviewMenuItem.classList.add("visible");
                const overviewMenuItemLink = overviewMenuItem.querySelector("a");
                if (overviewMenuItemLink) {
                    overviewMenuItemLink.href = linkSource;
                }
                subMenu.prepend(overviewMenuItem);
                subMenuWrapper.classList.toggle("visible");
                backButton.classList.toggle("visible");
                rootMenuItems.forEach(rootMenuItem => {
                    rootMenuItem.classList.add("hidden");
                })
            });
        });

    },
    toggleSubMenus: function () {
        // sub menu: open/close outside of text, follow link on text click
        const subMenuHeadings = document.querySelectorAll(".sub-menu.level_1 > li.menu_item_has_children > a");
        subMenuHeadings.forEach(function (link) {
            link.addEventListener("click", function (event) {
                const padding = 16 + 10;
                const textWidth = (link.children[0].offsetWidth + padding);
                if (event.offsetX > textWidth) {
                    // Clicked on the right half
                    event.preventDefault();
                    for (let i = 0; i < subMenuHeadings.length; i++) {
                        if (subMenuHeadings[i] !== link) {
                            subMenuHeadings[i].parentElement.classList.remove("is-open");
                        }
                    }
                    link.parentElement.classList.toggle("is-open");
                }
                // else follow default link behavior and close menu (in case)
            });
        });
    },
};