app.components.header = {
    onReady: function () {
        return document.querySelector('.header') !== null;
    },

    addListener: function () {
        const header = document.querySelector('header.header');
        if (!header) {
            return;
        }
        window.addEventListener('scroll', () => {
            header.classList.toggle('scrolled', window.scrollY > 0);
        });
    }
};