class TeaserComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', this.handleClick);
        this.truncateMultiline(document.querySelectorAll('.layout-small .teaser__excerpt p'), 3);
    }

    truncateMultiline(selector, lines) {
        selector.forEach(el => {
            let lineHeight = parseFloat(getComputedStyle(el).lineHeight);
            let maxHeight = lineHeight * lines;

            while (el.scrollHeight > maxHeight) {
                el.textContent = el.textContent.replace(/\s+\S*$/, '…');
            }
        });
    }

    handleClick(event) {
        event.preventDefault();
        const links = Array.from(this.querySelectorAll('a'));
        const link = links[0].href;
        if (link) {
            window.location.href = link;
        }
        return false;
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.handleClick);
    }
}

customElements.define('teaser-component', TeaserComponent);
