import {startsWith} from "lodash/string";

class AnchorComponent extends HTMLElement {
    constructor() {
        super();
        this.handleScroll = this.handleScroll.bind(this);
    }

    connectedCallback() {
        window.addEventListener('scroll', this.handleScroll);
    }

    disconnectedCallback() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    isElementInViewport(el) {

        const rect = el.getBoundingClientRect();

        return (
            rect.top >= document.querySelector('header').offsetHeight &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) - window.innerHeight * 0.3
        );
    }

    handleScroll() {
        let active = false;
        this.querySelectorAll('a').forEach(anchor => {
            const containerId = anchor.getAttribute('href');
            if (startsWith(containerId, '#')) {
                const container = document.querySelector(containerId);
                if (container) {
                    if (this.isElementInViewport(container) && active === false) {
                        anchor.classList.add('active');
                        active = true;
                    } else {
                        anchor.classList.remove('active');
                    }
                }
            }
        });
    }
}

customElements.define('anchor-component', AnchorComponent);
