app.components.hero = {
    onReady: function () {
        if ($('.hero').length) {
            return true;
        }
    },
    addListener: function () {
        const that = this;
        setTimeout(that.cloneTracks, 400);
    },
    cloneTracks: function () {
        const trackContainer = document.querySelector('.track-container');
        const track = document.querySelector('.track');

        if (!trackContainer || !track) return;

        document.querySelectorAll('.track-clone').forEach(clone => clone.remove());
        const clone = track.cloneNode(true);
        const computedStyle = window.getComputedStyle(track);
        clone.classList.add('track-clone'); // Add class to identify clones
        clone.style.width = computedStyle.width;
        clone.style.height = computedStyle.height;

        const animation = 'scroll-left 50s linear infinite';
        track.style.animation = animation;
        clone.style.animation = animation;

        trackContainer.appendChild(clone);
    },
};