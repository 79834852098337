import ScrollReveal from 'scrollreveal'

$(function () {
    ScrollReveal().reveal('main > .section > .container', {
        duration: 300,
        origin: 'bottom',
        distance: '30px',
        easing: 'ease-in-out',
        interval: 150,
        delay: 250,
        useDelay: 'onLoad'
    });
});
