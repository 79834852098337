app.components.infobanner = {
    onReady: function () {
        const banner = document.querySelector('.infobanner');
        if (!banner) {
            return false;
        }

        // Show the banner only if it hasn’t been dismissed in this session
        if (!sessionStorage.getItem('infobanner_closed')) {
            banner.classList.remove('hidden');
            return true;
        }

        return false;
    },

    addListener: function () {
        const closeIcon = document.querySelector('.infobanner .close');
        if (!closeIcon) {
            return;
        }

        closeIcon.addEventListener('click', function () {
            const banner = this.closest('.infobanner');
            if (banner) {
                banner.classList.add('hidden');
                sessionStorage.setItem('infobanner_closed', '1');
            }
        });
    }
};

