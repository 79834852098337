app.components.pageteaser = {
    onReady: function () {
        if ($('.c-teaser').length) {
            return true;
        }
    },
    addListener: function () {
        $(document).on('click', '.c-teaser', function () {
            const that = $(this);
            const link = that.find('a').eq(0).attr('href');
            if (link && that.find('a').length === 1) {
                window.location.href = link;
            }
            return false;
        });
    }
};
