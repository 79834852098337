class LogoCarpetComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
    }

    disconnectedCallback() {
    }
}

customElements.define('logo_carpet-component', LogoCarpetComponent);
