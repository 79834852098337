class VideoComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", () => this.initialize());
        } else {
            this.initialize();
        }

    }

    initialize() {
        const videoSources = this.querySelectorAll('video');
        this.style.display = "block";

        videoSources.forEach((video) => {

            // Fitvids
            video.style.width = "100%";
            video.style.height = "auto";
            video.style.maxWidth = "100%";
            video.style.aspectRatio = `${video.getAttribute(
                "width"
            )} / ${video.getAttribute("height")}`;
            video.removeAttribute("height");
            video.removeAttribute("width");

            // Randomize the start time of the video
            if (video.hasAttribute('rand')) {
                video.addEventListener('loadedmetadata', function () {
                    this.currentTime = Math.random() * (this.duration - 1) + 1;
                }, false);
            }

            // Play video on click
            if (video.hasAttribute('autoplay')) {
                video.parentNode.classList.add('playing');
            }
        });

        document.body.addEventListener('click', (event) => {
            if (event.target.closest('video-component')) {
                let player = event.target.querySelector('video');

                if (player.hasAttribute('controls')) {
                    return;
                }

                event.preventDefault();

                if (player.paused) {
                    player.play();
                    event.target.classList.add('playing');
                } else {
                    player.pause();
                    event.target.classList.remove('playing');
                }
            }
        });
    }

    disconnectedCallback() {
    }
}

customElements.define('video-component', VideoComponent);
