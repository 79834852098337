app.components.history = {
    onReady: function () {
        if ($('.history').length) {
            return true;
        }
    },
    addListener: async function () {
        try {
            const [Swiper, swiperModules] = await Promise.all([
                import(/* webpackChunkName: "swiper" */ 'swiper'),
                import(/* webpackChunkName: "swiperPagination" */ 'swiper/modules'),
                import(/* webpackChunkName: "swiper" */ 'swiper/css')
            ]);
            const SwiperLib = Swiper.default;
            $('.history').each(function () {

                const containter = $(".swiper-container", this);

                let swiper = {
                    container: containter[0],
                    params: {
                        speed: 1200,
                        spaceBetween: 40,
                        loop: false,
                        slidesPerView: 'auto',
                        watchOverflow: true,
                        modules: [swiperModules.Pagination],
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'custom',
                            clickable: true,
                            renderCustom: function (swiper, current, total) {
                                let output = '';
                                $.each(swiper.slides, function (index, slide) {
                                    const year = $('.history__year', slide).html();
                                    output += '<span class="swiper-pagination-bullet' + (current - 1 === index ? ' swiper-pagination-bullet-active' : '') + '">' + year + '</span>';
                                });
                                return output;
                            }
                        }
                    }
                };

                if (typeof window.swiperwAccessibilityParameters !== 'undefined') {
                    swiper.params.a11y = window.swiperwAccessibilityParameters;
                }

                const SwiperInstance = new SwiperLib($(".swiper-container", this)[0], swiper.params);

                SwiperInstance.on('slideChange', function (swiper) {
                    scrollToElement(containter, 0, 500);
                });

            });
        } catch (e) {
            console.error('Error loading modules:', error);
        }
    }
};
