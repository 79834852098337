class ProcessComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
    }

    disconnectedCallback() {
    }
}

customElements.define('process-component', ProcessComponent);
