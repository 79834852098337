app.components.gallery = {
    global: false,
    onReady: function () {
        if ($(".gallery").length) {
            return true;
        }
    },
    addListener: function () {
        // $("a[rel='lightbox']").fancybox();
    },
    resize: function () {

    },
    scroll: function () {

    }
};
