app.components.languages = {
    onReady: function () {
        if ($('.languages').length) {
            return true;
        }
    },
    addListener: function () {

        const dropdowns = document.querySelectorAll('.languages');

        dropdowns.forEach(dropdown => {
            const select = dropdown.querySelector('.languages__select');
            const menu = dropdown.querySelector('.languages__menu');
            const options = dropdown.querySelectorAll('.languages__menu li');

            select.addEventListener('click', () => {
                select.classList.toggle('select-clicked');

                menu.classList.toggle('menu-open');
            });

            options.forEach(option => {
                option.addEventListener('click', () => {
                    select.classList.remove('select-clicked');
                    menu.classList.remove('menu-open');

                    options.forEach(option => {
                        option.classList.remove('active');
                    });
                    option.classList.add('active');
                });
            });
        });

    }
};
